<template>
  <div>
    <el-row>
      <el-col :span="24">
        <img :src="bg" alt="123" class="resize" style="margin-bottom: -7px;">
        <div class="navbar">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home/support' }">支持</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home/support/support_video' }">下载中心</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" style="padding: 0 25%;">
        <div class="main_head">
          <div class="head_info">
            <p style="font-size: 25px; text-align: left;">CHIGEE旗下产品APP</p>
            <p style="font-size: 25px; text-align: left;">扫描二维码下载</p>
            <div class="head_content">
              <div>
                <img :src="img_erwei" alt="二维码" class="img_resize head_img_one">
                <p>CHIGEE APP</p>
              </div>
              <div class="bar"></div>
              <div>
                <img :src="img_erwei" alt="二维码" class="img_resize head_img_two">
                <p>XR-3 APP</p>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" style="padding: 0 25%;">
        <h1 class="title">手册</h1>
        <div class="info">
          <div class="menu">
            <el-input v-model="input" placeholder="搜索"></el-input>
            <el-link href="/#/home/support_download/down_one" :underline="false" class="menu_item">AIO-5</el-link>
            <el-link href="/#/home/support_download/video_two" :underline="false" class="menu_item">CG2</el-link>
            <el-link href="/#/home/support_download/video_three" :underline="false" class="menu_item">XR-3</el-link>
            <el-link href="/#/home/support_download/video_four" :underline="false" class="menu_item">G3</el-link>
          </div>
          <div class="content">
            <router-view />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bg: require('../../../../public/images/download/bg.png'),
      activeName: '1', // 手风琴
      input: '', // 搜索输入框
      img_erwei: require('../../../../public/images/support/erwei.png')
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  background-color: #f8f8f8;
}
.main_head {
  height: 420px;
  // background-color: pink;
  margin-top: 100px;
  border: 1px dashed #111;
  background: url('../../../../public/images/support/download/蒙版组 35@2x.png') no-repeat;
  background-size: 100% 100%;
  .head_info {
    width: 40%;
    height: 420px;
    // background-color: purple;
    padding: 80px 0 200px 50px;
  }
  .bar {
    width: 1px;
    height: 120px;
    background-color: #111;
  }
  .head_content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
  }
  .img_resize {
    width: auto;
    height: auto;
    max-width: 120px;
    max-height: 120px;
  }
}
.navbar {
  height: 40px;
  padding: 15px 25% 0;
  background: #dfdfdf;
}
.resize {
  width: 100%;
  height: 100%;
}
.title {
  margin-top: 80px;
  height: 60px;
  line-height: 60px;
  // background-color: pink;
  text-align: left;
  border-bottom: 1px solid #dfdfdf;
  text-indent: 1em;
}
.info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  .menu {
    flex: 1;
    height: 600px;
    border-right: 1px solid #dfdfdf;
    ::v-deep .el-input__inner {
      width: 95%;
      background-color: #ebebeb;
      border: none;
      margin-top: 40px;
    }
    ::v-deep .el-collapse-item__header {
      font-size: 16px;
      background-color: #f8f8f8;
      border-bottom: 0;
    }
    ::v-deep .el-collapse-item__wrap {
      background-color: #f8f8f8;
      border-bottom: 0;
    }
    ::v-deep .el-collapse-item__content {
      padding: 0;
    }
    .menu_item {
      width: 95%;
      height: 40px;
      line-height: 40px;
      text-indent: -7em;
      margin-top: 8px;
    }
    .menu_item:hover {
      background-color: #ebebeb;
      border-left: 2px solid #0088fe;
    }
  }
  .content {
    flex: 3;
    // background-color: green;
    // height: 300px;
  }
}
</style>